@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=optional');
@import 'react-loading-skeleton/dist/skeleton.css';
@import 'react-toastify/dist/ReactToastify.css';
@import '@spheron/ui-library/dist/css/index.css';

html,
body {
  color: var(--base-para-text-color);
  background-color: var(--base-bg-color);
  font-family: 'Inter', sans-serif;
}

.Toastify {
  position: relative;
  z-index: 99999;
}
.Toastify__toast {
  border-radius: 4px;
  z-index: 9999999999;
  padding: 8px !important;
}
.Toastify__toast-body {
  padding: 0px !important;
}
.Toastify__close-button,
.Toastify__close-button--colored {
  color: var(--feedback-default-cross);
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  padding: var(--spacing-4) !important;
  background: var(--feedback-error-bg);
}
.Toastify__toast-theme--colored.Toastify__toast--info {
  padding: var(--spacing-4) !important;
  background: var(--feedback-info-bg);
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  padding: var(--spacing-4) !important;
  background: var(--feedback-success-bg);
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  padding: var(--spacing-4) !important;
  background: var(--feedback-warning-bg);
}

.react-loading-skeleton {
  --base-color: var(--color-neutral-neutral-strong-neutral5) !important;
  --highlight-color: var(--color-neutral-neutral-strong-neutral20) !important;
}
.dark .react-loading-skeleton {
  --base-color: var(--color-neutral-neutral-strong-neutral200) !important;
  --highlight-color: var(--color-neutral-neutral-strong-neutral400) !important;
}
